<script setup lang="ts">
import type { Category } from '@shopware-pwa/types';
import { useCategorySearch } from '@shopware-pwa/composables-next';
import type { Ref } from 'vue';
import { getCategoryBreadcrumbs } from '@shopware-pwa/helpers-next';
import { useCmsHead } from '@/composables/useCmsHead';

const props = defineProps<{
  navigationId: string;
}>();

const { search } = useCategorySearch();
const route = useRoute();
const { t } = useI18n();

const { data: categoryResponse } = await useAsyncData(
  'cmsNavigation' + props.navigationId,
  async () => {
    const category = await search(props.navigationId, {
      withCmsAssociations: true,
      query: {
        ...route.query,
      },
    });
    return category;
  },
);

const breadcrumbs = getCategoryBreadcrumbs(categoryResponse.value, {
  startIndex: 2,
});
useBreadcrumbs(breadcrumbs);

const { category } = useCategory(categoryResponse as Ref<Category>);
useCmsHead(category, { mainShopTitle: t('generalShopName') });
</script>

<template>
  <!-- <LayoutBreadcrumbs /> -->
  <div class="frontend-navigation-page pt-25 lg:pt-40">
    <SharedCategoryHero
      v-if="category.translated.customFields?.custom_catalog_fields_show_hero"
      :custom-fields="category.translated.customFields"
    />
    <div
      class="lg:px-0"
      :class="
        category.translated.customFields?.custom_catalog_fields_show_hero
          ? 'cms-section-default pt-20'
          : ''
      "
    >
      <CmsPage v-if="category?.cmsPage" :content="category.cmsPage" />
    </div>
  </div>
</template>
